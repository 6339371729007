.NotWar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(4px);
    z-index: 1945;
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        &__ukraine {
            width: 200px;
            height: 150px;
            position: relative;
            margin-bottom: 20px;
            &:before {
                height: 50%;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                background-color: #0057b8;
            }
            &:after {
                height: 50%;
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #ffd700;
            }
        }
        a {
            color: rgba(255, 255, 255, 0.5);
            font-size: 40px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
                cursor: pointer;
                color: white;
                text-decoration: underline;
            }
        }
    }
}