.ContactPage {
    &__title {
        font-size: var(--size-font-block-title);
        text-align: center;
        color: var(--color-text);
        margin: 30px 0;
    }
    &__description {
        line-height: 135%;
        margin-bottom: 15px;
        color: var(--color-text);
        background-color: rgba(0, 0, 0, 0.04);
        font-size: 14px;
        padding: 10px;
        border-radius: 5px;
    }
    &__form {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
    &__content {
        margin: 30px auto;
        max-width: 700px;
        &__double {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 15px;
        }
        &__row {
            &__extended {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
}