$size-site: (
    "-page-width": 1200px,
    "-header-height": 50px
);
$size-site-fonts: (
    "-base": 16px,
    "-hero-title": 56px,
    "-hero-subtitle": 28px,
    "-block-title": 24px,
);

$size-site-fonts-500: (
    "-base": 16px,
    "-hero-title": 35px,
    "-hero-subtitle": 20px,
    "-block-title": 24px
);

:root {
    @each $name, $value in $size-site {
        --size#{$name}: #{$value};
    }
    @each $name, $value in $size-site-fonts {
        --size-font#{$name}: #{$value};
    }

    @media screen and (max-width: 500px) {
        @each $name, $value in $size-site-fonts-500 {
            --size-font#{$name}: #{$value};
        }
    }
}