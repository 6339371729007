.Header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--color-background-header);
    z-index: var(--z-header-area);
    backdrop-filter: blur(5px);
    box-shadow: 0 2px 40px rgba(0,0,0,.1);
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: var(--size-header-height);
    }
}