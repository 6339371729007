.NotFound {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &__wrapper {
        padding: 50px 0;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__image {
        background-image: url('../../Resources/NotFound.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 100%;
        height: 250px;
    }
    &__title {
        font-size: 40px;
        font-weight: 700;
        margin: 0;
        text-align: center;
        color: var(--color-text);
        box-sizing: border-box;
    }
    &__description {
        box-sizing: border-box;
        color: var(--color-text);
        font-size: 16px;
        line-height: 135%;
        margin: 15px 0 0;
        text-align: center;
    }
    &__link {
        margin: 15px 0 0;
        text-align: center;
        font-size: 16px;
        line-height: 135%;
        color: var(--color-accent);
        text-decoration: none;
        transition: color 150ms ease-in-out;
        &:hover {
            cursor: pointer;
            color: var(--color-accent-hover);
            text-decoration: underline;
        }
    }
}