.HeaderNavigation {
    display: flex; 
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    &__link {
        color: var(--color-text);
        position: relative;
        text-decoration: none;
        transition: color 150ms ease-in-out;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &__icon {
            font-size: 1.3rem;
            margin-right: 5px;
        }
        &:after {
            content: '';
            width: 0%;
            height: 2px;
            background-color: transparent;
            position: absolute; 
            bottom: -5px;
            left: 50%;
            transition: all 100ms ease-in-out;
        }
        &:hover {
            cursor: pointer;
            color: var(--color-accent-hover);
            &:after {
                width: 100%;
                left: 0%;
                background-color: var(--color-accent-hover);
            }
        }
    }
}