.Hero {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-image: url('../../Resources/hero-background.svg');
    background-position: left left;
    background-size: 200px;
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 200px 0 250px;
    }
    &__logo {
        background-image: url('../../Resources/logo_small.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 90px;
        height: 90px;
        margin-bottom: 15px;
    }
    &__title, &__subtitle {
        margin: 0;
        padding: 0;
        color: var(--color-text);
        text-align: center;
        z-index: 1;
        position: relative;
        line-height: 1;
    }
    &__title {
        font-size: var(--size-font-hero-title);
        font-weight: bold;
    }
    &__subtitle {
        font-size: var(--size-font-hero-subtitle);
        font-weight: 400;
        margin-top: 20px;
    }
    @media screen and (max-width: 500px) {
        &__subtitle {
            margin-top: 15px;
        }
    }
}