@import './CommonStyles/sizes.scss';
@import './CommonStyles/fonts.scss';
@import './CommonStyles/colors.scss';
@import './CommonStyles/indexes.scss';

* {
    padding: 0;
    margin: 0;
}
body, html {
    box-sizing: border-box;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: var(--size-font-base);
    background-color: var(--color-background-base);
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}