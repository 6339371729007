.ComponentsPage {
    display: block;
    margin: 30px 0;
    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;

        @media screen and (max-width: 620px) {
            grid-template-columns: 1fr;
        }
        @media screen and (max-width: 460px) {
            padding: 0 10px;
        }
    }
    &__pageTitle {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: center;
        font-size: 48px;
        margin-bottom: 30px;
        font-weight: bold;
        color: var(--color-text);
        @media screen and (max-width: 620px) {
            grid-column-start: 1;
            grid-column-end: 2;
        }
    }
    &__block {
        padding: 30px;
        border: 1px solid var(--color-border);
        border-radius: 10px;
        &__fullpage {
            grid-column-start: 1;
            grid-column-end: 3;
            @media screen and (max-width: 620px) {
                grid-column-start: 1;
                grid-column-end: 2;
            }
        }

        @media screen and (max-width: 460px) {
            padding: 15px;
        }
    }
    &__title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        color: var(--color-text);
    }
    &__radios {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        &:not(:last-child) { border-bottom: 1px solid var(--color-border); padding-bottom: 15px; margin-bottom: 15px; }
    }
}