.Contacts {
    background-color: var(--color-background-contacts);
    &__wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 70px 0;
    }
    &__title {
        color: var(--color-text);
        font-size: var(--size-font-block-title);
        font-weight: 700;
        line-height: 135%;
        margin-bottom: 25px;
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }
    &__item {
        color: var(--color-text);
        font-size: 50px;
        transition: all 150ms ease-in-out;
        &:hover {
            cursor: pointer;
            color: var(--color-accent-hover);
            transform: scale(1.05) translateY(-5px);
        }
    }
}