.WorksItem {
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-gap: 20px;
    padding: 20px 0;
    &:not(:last-child) {
        border-bottom: 1px solid var(--color-border);
    }
    &__logo {
        width: 56px;
        height: 56px;
        img {
            width: 56px;
        }
        &__empty {
            background-color: var(--color-accent);
            opacity: 0.7;
            border-radius: 10px;
            background-image: url('../../Resources/works/emptyWork.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 30px;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        &__big {
            display: grid;
            .WorksItem__city {
                margin-bottom: 10px;
            }
        }
    }
    &__company {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: var(--color-text);
        &__big {
            font-weight: bold;
            font-size: 18px;
            line-height: 1.35;
            color: var(--color-text);                
        }
    }
    &__positionItem {
        position: relative;
        padding-left: 35px;
        padding-top: 5px;
        padding-bottom: 5px;
        .WorksItem__position {
            font-weight: bold;
            margin-top: 5px;
        }
        &:after, &:before {
            background-color: var(--color-background-timeline-elements);
        }
        &:before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 10em;
            position: absolute;
            left: 0;
            top: calc(50% - 5px);
        }
        &:after {
            content: '';
            width: 2px;
            height: 100%;
            position: absolute;
            left: 4px;
            top: 0;

        }
    }
    &__city {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.35;
        color: var(--color-text-grey);
        margin-top: 5px;
    }
    &__position {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.35;
        color: var(--color-text);
        margin-top: 5px;
    }
    &__time {
        color: var(--color-text-grey);
        font-size: 14px;
        margin-top: 5px;
    }
    &__website {
        color: var(--color-accent);
        text-decoration: none;
        font-size: 14px;
        margin-top: 5px;
        i {
            margin-right: 5px;
        }
    }
    &__description {
        color: var(--color-text);
        font-size: 14px;
        line-height: 135%;
        margin-top: 5px;
    }
}