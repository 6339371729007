$backgrounds: (
    "-base": #ffffff,
    "-header": rgba(#fafafa, 0.9),
    "-timeline-elements": #d9d9d9,
    "-contacts": #f6f6f6,
    "-footer": #fafafa,
    "-radio": #e6e6e6,
    "-grey": #fafafa,
    "-sublinks": #f9f9f9,
);
$colors: (
    "-text": #1f1f1d,
    "-text-grey": #666666,
    "-border": #ededed,
    "-accent": #1876f2,
    "-accent-hover": darken(#1876f2, 5),
    "-accent-active": darken(#1876f2, 10),
    "-red": #c90000,
    "-red-hover": darken(#c90000, 5),
    "-red-lighten": rgba(#c90000, 0.3),
);

$backgrounds-dark: (
    "-base": #000000,
    "-header": rgba(#0f0f0f, 0.9),
    "-timeline-elements": #2c2c2c,
    "-contacts": #0a0a0a,
    "-footer": #111,
    "-sublinks": #1d1d1d,
);

$colors-dark: (
    "-text": #e1e1e1,
    "-text-grey": #939393,
    "-border": #202020,
    "-accent": #1876f2,
    "-accent-hover": darken(#1876f2, 5),
    "-accent-active": darken(#1876f2, 10),
);

:root {
    @each $name, $value in $colors {
        --color#{$name}: #{$value};
    }
    @each $name, $value in $backgrounds {
        --color-background#{$name}: #{$value};
    }

    @media screen and (prefers-color-scheme: dark) {
        @each $name, $value in $colors-dark {
            --color#{$name}: #{$value};
        }
        @each $name, $value in $backgrounds-dark {
            --color-background#{$name}: #{$value};
        }
    }
}