$fontsPath: '../resources/fonts/';

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-SemiBold.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-ExtraBold.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-Medium.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-LightItalic.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-Bold.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-Light.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-MediumItalic.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-Italic.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-Regular.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fontsPath}OpenSans-BoldItalic.woff2') format('woff2'),
        url('#{$fontsPath}OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

