.LanguageSelector {
    display: block;
    position: relative;
    &__selector {
        color: var(--color-text);
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        user-select: none;
        i {
            font-size: 30px;
            margin-right: 5px;
        }
        &__icon {
            background-image: url('../../../Resources/locale.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            width: 25px;
            height: 25px;
            display: block;
            @media screen and (prefers-color-scheme: dark) {
                background-image: url('../../../Resources/locale-light.svg');
            }
        }
        &:hover {
            cursor: pointer;
            color: var(--color-accent-hover);
            .LanguageSelector__selector__icon {
                background-image: url('../../../Resources/locale_accent.svg');
            }
        }
    }
    &__variants {
        display: none;
        position: absolute;
        background-color: var(--color-background-contacts);
        border-radius: 3px;
        border: 1px solid var(--color-border);
        overflow: hidden;
        top: 100%;
        right: 0;
        &__item {
            padding: 10px 10px 10px 30px;
            font-size: 14px;
            color: var(--color-text);
            position: relative;
            &:before {
                content: '';
                width: 22px;
                height: 17px;
                left: 2px;
                top: calc(50% - (17px / 2));
                position: absolute;
                background-color: var(--color-background-contacts);
            }
            &:after {
                content: '';
                top: calc(50% - (15px / 2));
                left: 3px;
                position: absolute;
                width: 20px;
                height: 15px;
                background-color: var(--color-background-contacts);
                background-repeat: no-repeat;
                background-size: cover;
            }
            &:not(:last-child) { border-bottom: 1px solid var(--color-border); }
            &:hover {
                background-color: rgba($color: #000, $alpha: 0.1);
                cursor: pointer;
            }
        }
        &__show {
            display: block;
        }
    }

    &__ru {
        &:after {
            background-image: url('../../../Resources/locale/ru.svg');
            background-color: transparent;
        }
    }
    &__en {
        &:after {
            background-image: url('../../../Resources/locale/en.svg');
            background-color: transparent;
        }
    }
    &__uk {
        &:after {
            background-image: url('../../../Resources/locale/uk.svg');
            background-color: transparent;
        }
    }
}