$skillsIcons: (
    "html": url('../../Resources/skills/html.svg'),
    "js": url('../../Resources/skills/js.svg'),
    "ts": url('../../Resources/skills/ts.svg'),
    "css": url('../../Resources/skills/css.svg'),
    "scss": url('../../Resources/skills/scss.svg'),
    "webpack": url('../../Resources/skills/webpack.svg'),
    "react": url('../../Resources/skills/react.svg'),
    "php": url('../../Resources/skills/php.svg'),
    "django": url('../../Resources/skills/django.svg'),
    "python": url('../../Resources/skills/python.svg'),
    "git": url('../../Resources/skills/git.svg'),
    "sketch": url('../../Resources/skills/sketch.svg'),
    "figma": url('../../Resources/skills/figma.svg'),
    "phpstorm": url('../../Resources/skills/phpstorm.svg'),
    "pycharm": url('../../Resources/skills/pycharm.svg'),
    "vscode": url('../../Resources/skills/vscode.svg'),
    "telegram": url('../../Resources/skills/telegram.svg'),
    "node": url('../../Resources/skills/node.svg')
);
$skillsIcons-light: (
    'html': url('../../Resources/skills/html-light.svg'),
    'js': url('../../Resources/skills/js-light.svg'),
    'ts': url('../../Resources/skills/ts-light.svg'),
    'css': url('../../Resources/skills/css-light.svg'),
    'scss': url('../../Resources/skills/scss-light.svg'),
    'webpack': url('../../Resources/skills/webpack-light.svg'),
    'react': url('../../Resources/skills/react-light.svg'),
    'php': url('../../Resources/skills/php-light.svg'),
    'django': url('../../Resources/skills/django-light.svg'),
    'python': url('../../Resources/skills/python-light.svg'),
    'git': url('../../Resources/skills/git-light.svg'),
    'sketch': url('../../Resources/skills/sketch-light.svg'),
    'figma': url('../../Resources/skills/figma-light.svg'),
    'phpstorm': url('../../Resources/skills/phpstorm-light.svg'),
    'pycharm': url('../../Resources/skills/pycharm-light.svg'),
    'vscode': url('../../Resources/skills/vscode-light.svg'),
    'telegram': url('../../Resources/skills/telegram-light.svg'),
    'node': url('../../Resources/skills/node-light.svg')
);


.Skills {
    background-color: rgba(#CCC, 0.2);
    padding: 70px 0;
    &__title {
        font-size: var(--size-font-block-title);
        font-weight: bold;
        color: var(--color-text);
        text-align: center;
        margin-bottom: 25px;
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 50px;
        margin-top: 50px;
        @media screen and (max-width: 660px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (max-width: 430px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &__item {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        transition: all 150ms ease-in-out;
        @each $skill, $background in $skillsIcons {
            &__#{$skill} {
                background-image: $background;
            }
        }
        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
        @media screen and (prefers-color-scheme: dark) {
            @each $skill, $background in $skillsIcons-light {
                &__#{$skill} {
                    background-image: $background;
                }
            }
        }
    }
}