.Footer {
    background-color: var(--color-background-footer);
    flex: 0 0 auto;
    &__wrapper {
        padding: 10px 0;
        @supports(padding: unquote('max(0px)')) {
            padding-bottom: unquote('max(10px, env(safe-area-inset-bottom))');
        }
    }
    &__top {
        padding: 15px 0;
        margin: 0 0 15px;
        border-bottom: 1px solid var(--color-border);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__logo {
            position: relative;
        }
        &__social {
            position: relative;
            display: flex;
            gap: 20px;
            &__item {
                list-style: none;
            }
            &__link {
                font-size: 30px;
                line-height: 1;
                color: var(--color-text);
                transition: color 150ms ease-in-out, transform 150ms ease-in-out;
                display: block;
                &:hover {
                    cursor: pointer;
                    color: var(--color-accent-hover);
                    transform: translateY(-3px);
                }
            }
        }
    }
    &__copyright {
        font-size: 14px;
        line-height: 135%;
        text-align: left;
        color: var(--color-text);
    }
}