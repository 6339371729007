.Button {
    display: inline-flex;
    border: none;
    background-color: var(--color-accent);
    color: white;
    border-radius: 5px;
    padding: 15px;
    transition: all 150ms ease-in-out;
    font-size: 14px;
    &:hover {
        background-color: var(--color-accent-hover);
        cursor: pointer;
    }
    &__theme {
        &__primary {
            background-color: var(--color-accent);
            &:hover {
                background-color: var(--color-accent-hover);
            }
        }
        &__alert {
            background-color: var(--color-red);
            &:hover {
                background-color: var(--color-red-hover);
            }
        }
        &__link {
            background-color: transparent;
            color: var(--color-accent);
            padding: 0;
            &:hover {
                cursor: pointer;
                color: var(--color-accent-hover);
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }
}