.Logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: var(--color-text);
    transition: color 150ms ease-in-out;
    &__icon {
        line-height: 1;
        margin-right: 7px;
        background-image: url('../../../Resources/logo_small.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 20px;
        height: 20px;
    }   
    &__text {
        font-size: 16px;
    }

    &__big {
        .Logo__icon {
            width: 50px;
            height: 50px;
            background-image: url('../../../Resources/logo_small.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    &:hover {
        color: var(--color-accent-hover);
    }
}