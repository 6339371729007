$padding-left-right: 10px;

.Input {
    border: 1px solid var(--color-border);
    border-radius: 5px;
    overflow: hidden;
    display: block;
    position: relative;
    background-color: white;
    cursor: text;
    &__label {
        position: absolute;
        pointer-events: none;
        top: calc(50% - 7px);
        left: $padding-left-right;
        font-size: 14px;
        line-height: 1;
        color: var(--color-text);
        transition: all 150ms ease-in-out;
        &__has-text {
            top: calc(50% - 15px);
            left: $padding-left-right;
            font-size: 12px;
            opacity: 0.3;
        }
    }
    &__text, &__textarea {
        &__field {
            border: none;
            background-color: none;
            outline: none;
            appearance: none;
            font-family: 'Open Sans', sans-serif;
            box-shadow: none;
            margin: 25px 10px 10px;
            width: calc(100% - 20px);
            font-size: 14px;
            color: var(--color-text);
        }
    }
    &__textarea {
        &__field {
            resize: none;
            height: 18px;
            line-height: 1.25;
            width: calc(100% - 20px);
            appearance: none;
        }
    }
    &__inputTextarea {
        box-sizing: border-box;
        .Input__label {
            top: 20px;
            &__has-text {
                top: 10px;
            }
        }
    }
    &__inputRadio {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        &:hover {
            cursor: pointer;
        }
        &__input {
            width: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
            padding: 0;
            margin: 0;
            display: none;
            appearance: none;
        }
        &__ellipse {
            width: 20px;
            height: 20px;
            background-color: var(--color-background-radio);
            margin-right: $padding-left-right;
            border-radius: 10em;
            position: relative;
            flex-shrink: 0;
            &:after {
                width: 0;
                height: 0;
                top: 10px;
                left: 10px;
                content: '';
                position: absolute;
                border-radius: 10em;
                transition: all 150ms ease-in-out;
            }
        }
        &__input:checked + .Input__inputRadio__ellipse:after {
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
            background-color: var(--color-accent);
        }
        &__label {
            font-size: 16px;
            color: var(--color-text);
            line-height: 135%;
        }
        &__error {
            .Input__inputRadio__label {
                color: var(--color-red);
                text-decoration: underline;
            }
        }
    }
    &__inputCheckbox {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        &:hover {
            cursor: pointer;
        }
        &__input {
            width: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
            padding: 0;
            margin: 0;
            display: none;
            appearance: none;
        }
        &__square {
            width: 20px;
            height: 20px;
            background-color: var(--color-background-radio);
            margin-right: $padding-left-right;
            border-radius: 3px;
            position: relative;
            flex-shrink: 0;
            &:after {
                width: 0;
                height: 0;
                top: 10px;
                left: 10px;
                content: '';
                position: absolute;
                border-radius: 2px;
                transition: all 150ms ease-in-out;
            }
        }
        &__input:checked + .Input__inputCheckbox__square:after {
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
            background-color: var(--color-accent);
        }
        &__label {
            font-size: 16px;
            color: var(--color-text);
            line-height: 135%;
        }
        &__error {
            .Input__inputCheckbox__label {
                color: var(--color-red);
                text-decoration: underline;
            }
        }
    }
    &__error {
        border: 1px solid var(--color-red);
    }
    &__disabled {
        background-color: var(--color-background-grey);
        cursor: auto;
        .Input__text__field {
            background-color: transparent;
        }
        .Input__textarea__field {
            background-color: transparent;
        }
    }
}