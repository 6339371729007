.Typography {
    h1 {
        font-size: 44px;
        font-weight: bold;
        line-height: 132%;
        margin-bottom: 30px;
        color: var(--color-text);
    }
    h2 {
        font-size: 24px;
        line-height: 135%;
        font-weight: bold;
        color: var(--color-text);
    }
    p {
        font-size: 16px;
        line-height: 176%;
        margin-bottom: 25px;
        color: var(--color-text);
    }
    a {
        color: var(--color-text);
        text-decoration: none;
        position: relative;
        border-bottom: 2px solid var(--color-accent);
        &:hover {
            cursor: pointer;
            border-bottom: 2px dashed var(--color-accent);
        }
    }
    ul {
        padding-left: 0;
        &:not(:last-child) { margin-bottom: 25px; }
        li {
            list-style: none;
            position: relative;
            padding-left: 20px;
            font-size: 16px;
            line-height: 176%;
            color: var(--color-text);
            &:not(:last-child) { margin-bottom: 5px; }
            &:before {
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 10em;
                background-color: var(--color-accent);
                position: absolute;
                top: 10px;
                left: 0;
            }
        }
    }
    ol {
        padding-left: 15px;
        &:not(:last-child) { margin-bottom: 25px; }
        li {
            padding-left: 5px;
            position: relative;
            font-size: 16px;
            line-height: 176%;
            color: var(--color-text);
            &:not(:last-child) { margin-bottom: 5px; }
        }
    }
}

.Spoiler {
    --spoiler-background-color: #e6e6e6;
    --spoiler-background-color-hover: #{darken(#e6e6e6, 5)};
    @media screen and (prefers-color-scheme: dark) {
        --spoiler-background-color: #242424;
        --spoiler-background-color-hover: #444;
    }
    hr {
        margin: 20px 0;
        height: 3px;
        border: none;
        background-color: var(--color-border);
    }
    &__content {
        margin-top: 10px;
        display: none;
    }
    &__button {
        background-color: var(--spoiler-background-color);
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        text-transform: uppercase;
        user-select: none;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        color: var(--color-text);
        transition: all 150ms ease-in-out;
        &:hover {
            cursor: pointer;
            background-color: var(--spoiler-background-color-hover);
        }
    }

    &__show {
        .Spoiler__content {
            display: block;
        }
    }
}

.Sublinks {
    border-radius: 7px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 4px 0 0 var(--color-accent);
    background-color: var(--color-background-sublinks);
    &__label {
        font-size: 14px;
        line-height: 16px;
        padding: 20px 20px 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        color: var(--color-text);
    }
    &__list {
        &__item {
            padding: 10px 20px !important;
            &:not(:last-child) {
                border-bottom: 1px solid var(--color-border);
            }
            &:before {
                display: none;
            }
            a {
                border: none;
                &:hover {
                    border: none;
                }
            }
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
}